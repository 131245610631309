import {createContext, useState} from 'react';

const DataContext = createContext();

const DataProvider = ({children}) => {
  const [loading, setLoading] = useState(false);
  const [searchKey, setSearchKey] = useState('');
  const [searchResults, setSearchResults] = useState({});
  const [paymentData, setPaymentData] = useState({});
  const [clientName, setClientName] = useState({});

  const value = {
    loading,
    setLoading,
    searchKey,
    setSearchKey,
    searchResults,
    setSearchResults,
    clientName,
    setClientName,
    paymentData,
    setPaymentData,
  };
  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};
export {DataContext, DataProvider};
