import React, {useContext, lazy, Suspense} from 'react';
import {Routes, Route, useLocation, Navigate} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap-icons/font/bootstrap-icons.css';
import '../src/assets/css/style.css';
import routes from './routes/routes';
import Errors from './pages/error/Errors';
import Loader from './components/Loader';
import {DataContext} from './context/dataContext';
import TagManager from 'react-gtm-module'
// import ScrollToTop from "./components/ScrollToTop";
// import Header from "./components/Headers";
// import Footer from "./components/Footer";
const ScrollToTop = lazy(() => import('./components/ScrollToTop'));
const Header = lazy(() => import('./components/Headers'));
const Footer = lazy(() => import('./components/Footer'));

const tagManagerArgs = {
  gtmId: 'GTM-MHHMWMSD'
}
TagManager.initialize(tagManagerArgs)

const App = () => {

  window.dataLayer.push({
    event: 'pageview'
  });

  const {loading} = useContext(DataContext);
  const {pathname} = useLocation();
  return (
    <>
      {loading && <Loader />}
      <Suspense fallback={<Loader />}>
        <ScrollToTop />
        <Header />

        <Routes>
          {routes.map(({title, path, element}) => (
            <Route key={title} path={path} element={element} />
          ))}
          <Route key="404" path="/404" element={<Errors />} />
          <Route
            key="Unknown"
            path="*"
            element={<Navigate to="/404" replace />}
          />
        </Routes>
        <Footer
          isNew={
            pathname.includes('/404') ||
            pathname.includes('/number-plate-news') ||
            pathname.includes('/privacy-policy') ||
            pathname.includes('/about')
          }
        />
      </Suspense>
    </>
  );
};

export default App;
