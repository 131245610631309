import React, {lazy} from 'react';

// import Home from '../pages/home/Home';
// import Sell from '../pages/sell/Sell';
// import SearchResults from '../pages/searchResults/SearchResult';
// import Checkout from '../pages/checkout/Checkout';
// import Faq from '../pages/faq/Faq';
// import PlateViewer from '../pages/plateviewer/plateviewer';
// import PrivacyPolicy from '../pages/privacyPolicy/PrivacyPolicy';
// import BlogDetail from '../pages/blogdetail/BlogDetail';
// import About from '../pages/about/About';
// import Blog from '../pages/blog/Blog';
// import Enquiry from '../pages/enquiry/Enquiry';
// import Contact from '../pages/contact/Contact';
// import Offer from '../pages/offer/Offer';
// import Orderplate from '../pages/orderplate/Orderplate';
// import SecureReg from '../pages/secureyourreg/SecureReg';
// import Thankyou from '../pages/thankyou/Thankyou';
// import Valuation from '../pages/valuation/Valuation';
// import Orderconfirmation from '../pages/orderconfirmation/Orderconfirmation';
// import Termsconditions from '../pages/termsconditions/Termsconditions';
// import Orderplatecheckout from '../pages/orderplate/Orderplatecheckout';
// import AcrlyOrderconfirmation from '../pages/orderconfirmation/AcrlyOrderconfirmation';
// import BuyRegistration from '../pages/buyregistration/BuyRegistration';

const Home = lazy(() => import('../pages/home/Home'));
const Sell = lazy(() => import('../pages/sell/Sell'));
const SearchResults = lazy(() => import('../pages/searchResults/SearchResult'));
const Checkout = lazy(() => import('../pages/checkout/Checkout'));
const Faq = lazy(() => import('../pages/faq/Faq'));
const PlateViewer = lazy(() => import('../pages/plateviewer/plateviewer'));
const PrivacyPolicy = lazy(() =>
  import('../pages/privacyPolicy/PrivacyPolicy'),
);
const BlogDetail = lazy(() => import('../pages/blogdetail/BlogDetail'));
const About = lazy(() => import('../pages/about/About'));
const Blog = lazy(() => import('../pages/blog/Blog'));
const Enquiry = lazy(() => import('../pages/enquiry/Enquiry'));
const Contact = lazy(() => import('../pages/contact/Contact'));
const Offer = lazy(() => import('../pages/offer/Offer'));
const Orderplate = lazy(() => import('../pages/orderplate/Orderplate'));
const SecureReg = lazy(() => import('../pages/secureyourreg/SecureReg'));
const Thankyou = lazy(() => import('../pages/thankyou/Thankyou'));
const Valuation = lazy(() => import('../pages/valuation/Valuation'));
const Orderconfirmation = lazy(() =>
  import('../pages/orderconfirmation/Orderconfirmation'),
);
const Termsconditions = lazy(() =>
  import('../pages/termsconditions/Termsconditions'),
);
const Orderplatecheckout = lazy(() =>
  import('../pages/orderplate/Orderplatecheckout'),
);
const AcrlyOrderconfirmation = lazy(() =>
  import('../pages/orderconfirmation/AcrlyOrderconfirmation'),
);
const BuyRegistration = lazy(() =>
  import('../pages/buyregistration/BuyRegistration'),
);

const routes = [
  {
    title: 'Home',
    path: '/',
    element: <Home />,
  },
  {
    title: 'Sell',
    path: '/sell-number-plate',
    element: <Sell />,
  },
  {
    title: 'Search Results',
    path: '/search-results/:id',
    element: <SearchResults />,
  },
  {
    title: 'Checkout',
    path: '/checkout/:id',
    element: <Checkout />,
  },
  {
    title: 'FAQ',
    path: '/number-plate-faq',
    element: <Faq />,
  },
  {
    title: 'Plate Viewer',
    path: '/plate-viewer',
    element: <PlateViewer />,
  },
  {
    title: 'Privacy Policy',
    path: '/privacy-policy',
    element: <PrivacyPolicy />,
  },
  {
    title: 'About',
    path: '/about',
    element: <About />,
  },
  {
    title: 'Blog',
    path: '/number-plate-news',
    element: <Blog />,
  },
  {
    title: 'Blog Detail',
    path: '/blog/:id',
    element: <BlogDetail />,
  },
  {
    title: 'Registration Enquiry',
    path: '/registration-enquiry/:id',
    element: <Enquiry />,
  },

  {
    title: 'Contact',
    path: '/contact',
    element: <Contact />,
  },
  {
    title: 'Offer',
    path: '/offer/:id',
    element: <Offer />,
  },

  {
    title: 'Order Plate',
    path: '/buy-number-plates',
    element: <Orderplate />,
  },
  {
    title: 'Order Plate Checkout',
    path: '/orderplatecheckout',
    element: <Orderplatecheckout />,
  },

  {
    title: 'Secure Your Reg',
    path: '/secure-your-reg/:id',
    element: <SecureReg />,
  },

  {
    title: 'Thank You Reg',
    path: '/thank-you-reg',
    element: <Thankyou />,
  },

  {
    title: 'Thank You Acrylic',
    path: '/thank-you-acrylic',
    element: <Thankyou />,
  },

  {
    title: 'Thank You',
    path: '/thank-you-valuation',
    element: <Thankyou />,
  },
  {
    title: 'Thank You',
    path: '/thank-you-sell',
    element: <Thankyou />,
  },

  //
  {
    title: 'Order Confirmation',
    path: '/order-confirmation/:id',
    element: <Orderconfirmation />,
  },
  {
    title: 'AcrlyOrderconfirmation',
    path: '/acrlyorderconfirmation',
    element: <AcrlyOrderconfirmation />,
  },

  {
    title: 'Valuation',
    path: '/number-plate-valuation',
    element: <Valuation />,
  },

  {
    title: 'Terms Conditions',
    path: '/terms-and-conditions',
    element: <Termsconditions />,
  },
  {
    title: 'Buy Registrations',
    path: '/buy-registrations',
    element: <BuyRegistration />,
  },
];

export default routes;
