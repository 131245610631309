import React from "react";

const Loader = () => {
  return (
    <>
      {/* <div className="overlay" id="overlay"></div> */}
      <div className="loader-container" id="loader-container">
        <div className="loader"></div>
      </div>
    </>
  );
};

export default Loader;
