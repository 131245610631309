import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import arrow from "../../assets/images/arrow.svg";
import arrowWhite from "../../assets/images/arrowWhite.svg";

const Errors = () => {
  const [homePath, setHomePath] = useState(arrow);
  const [newsPath, setNewsPath] = useState(arrow);
  const [aboutPath, setAboutPath] = useState(arrow);
  const [contactPath, setContactPath] = useState(arrow);
  const [faqPath, setFaqPath] = useState(arrow);
  const navigate = useNavigate();
  return (
    <div className="main__page__content">
      <main>
        {/*hero section start*/}
        <section className="lg__errorhero--section lg-plate-hero-section">
          <div className="container">
            <div className="lg__hero-inner">
              <div className="hero-plate-col position-relative z-1 w-100">
                <h1 className="hero-heading1 text-white fys position-relative text-300">
                  404 Error
                </h1>
                <h2 className="hero-heading2 fys secondry-color mb-52 text-300">
                  Page not found
                </h2>
                <p className="text-white f18 fsi m-0">
                  Sorry we can't find that page
                </p>
              </div>
              <div className="lg-hero-btns">
                <p className="fsi text-white f18 m-0">
                  Let's get you back on track for your perfect plate :
                </p>
                <div className="btn-list-inner mt-3 mt-lg-4">
                  <ul className="btn-list d-flex align-items-center flex-wrap">
                    <li
                      className="buy-btn w-100"
                      onMouseEnter={() => {
                        setHomePath(arrowWhite);
                      }}
                      onMouseLeave={() => {
                        setHomePath(arrow);
                      }}
                    >
                      <Link
                        to="/"
                        className="fsi overflow-hidden d-flex align-items-center justify-content-between"
                      >
                        Home{" "}
                        <img
                          src={homePath}
                          alt=""
                          className="in-svg img-fluid w-100"
                        />
                      </Link>
                    </li>
                    <li
                      className="buy-btn w-100"
                      onMouseEnter={() => {
                        setNewsPath(arrowWhite);
                      }}
                      onMouseLeave={() => {
                        setNewsPath(arrow);
                      }}
                    >
                      <Link
                        to="/number-plate-news"
                        className="fsi color-black overflow-hidden d-flex align-items-center justify-content-between"
                      >
                        News{" "}
                        <img
                          src={newsPath}
                          alt=""
                          className="in-svg img-fluid w-100"
                        />
                      </Link>
                    </li>
                    <li
                      className="buy-btn w-100"
                      onMouseEnter={() => {
                        setAboutPath(arrowWhite);
                      }}
                      onMouseLeave={() => {
                        setAboutPath(arrow);
                      }}
                    >
                      <Link
                        to="/about"
                        className="fsi color-black overflow-hidden d-flex align-items-center justify-content-between"
                      >
                        About{" "}
                        <img
                          src={aboutPath}
                          alt=""
                          className="in-svg img-fluid w-100"
                        />
                      </Link>
                    </li>
                    <li
                      className="buy-btn w-100"
                      onMouseEnter={() => {
                        setContactPath(arrowWhite);
                      }}
                      onMouseLeave={() => {
                        setContactPath(arrow);
                      }}
                    >
                      <Link
                        to="/contact"
                        className="fsi color-black overflow-hidden d-flex align-items-center justify-content-between"
                      >
                        Contact{" "}
                        <img
                          src={contactPath}
                          alt=""
                          className="in-svg img-fluid w-100"
                        />
                      </Link>
                    </li>
                    <li
                      className="buy-btn w-100"
                      onMouseEnter={() => {
                        setFaqPath(arrowWhite);
                      }}
                      onMouseLeave={() => {
                        setFaqPath(arrow);
                      }}
                    >
                      <Link
                        to="/number-plate-faq"
                        className="fsi color-black overflow-hidden d-flex align-items-center justify-content-between"
                      >
                        FAQs{" "}
                        <img
                          src={faqPath}
                          alt=""
                          className="in-svg img-fluid w-100"
                        />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </div>
  );
};

export default Errors;
